import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RoleModel } from 'src/app/shared/models/role.model';
import { UserModel } from 'src/app/shared/models/user.model';
import { environment } from 'src/environments/environment';


const API_URL = environment.backend_url;

@Injectable({
  providedIn: 'root'
})
export class UserService {

// Http Options
httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

constructor(private httpClient: HttpClient) { }




  // Liste adherent
  getRole(): Observable<RoleModel[]> {

    return this.httpClient.get<RoleModel[]>(`${API_URL}/role/list`);
  }
    // Liste User
getUser(): Observable<UserModel[]> {

  return this.httpClient.get<UserModel[]>(`${API_URL}/util/list`);
}
  //  enregistrer User
  addUser(user: UserModel): Observable<UserModel> {

  return this.httpClient.post<UserModel>(`${API_URL}/util/enregistrer`, user, this.httpOptions
  );
}

}



