import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormatDate } from 'src/app/core/patterns/FormatDate';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ValidationService } from 'src/app/core/patterns/ValidationService';
import { ActeService } from 'src/app/core/services/acte.service';
import { AdherentService } from 'src/app/core/services/adherent.service';
import { BulletinSoinService } from 'src/app/core/services/bulletin-soin.service';
import { ActeModel } from 'src/app/shared/models/acte.model';
import { AdherentModel } from 'src/app/shared/models/adherent';
import { BulletinSoin } from 'src/app/shared/models/bulletinsoin.model';
import { GroupeActeModel } from 'src/app/shared/models/groupeacte.model';
import { environment } from 'src/environments/environment';
import { DocteurService } from 'src/app/core/services/docteur.service';
import { DocteurModel } from 'src/app/shared/models/docteur.model';
const API_URL = environment.backend_url;
@Component({
  selector: 'app-add-feuille-soin',
  templateUrl: './add-feuille-soin.component.html',
  styleUrls: ['./add-feuille-soin.component.scss']
})
export class AddFeuilleSoinComponent implements OnInit {

  apiUrl = API_URL;
  private adherent: BulletinSoin[];
  formAssure = null;
  formPrestatUne = null;
  formBulletinSoin = null;
  listAssure: AdherentModel[];
  listGroupeActe: GroupeActeModel[];
  listActe: ActeModel[];
  itemActe = null;
  typeAssure = '';
  categorieRecher = '';
  public formAdherent = {

    info_perso: null,
    piece_ident: null,
    situation: null
  } ;
  public stepForm = 1 ;
  loader = {active: false};
  submitted = false;
  selectedCheckboxAssure= null;
assureSelect: FormControl = new FormControl();
inputPlaceholderSearch = 'Choisir une categorie et un type d\'assuré';
piecidentcheck = '';
dateEtabliMatch = '' ;
dateNaissMatch = '';
stepperStatus = {
  assure: '',
  prestation: 'stepper--horizontal--disabled',
  info_perso: '',
  piece_ident: 'stepper--horizontal--disabled',
    situation: 'stepper--horizontal--disabled'
};
  erroMessage: any ='';
  activeMessage: boolean = false;
  alertDanger: boolean = true;
  alertIcon: string='fa fa-times-circle'
  infoUser: any;
// modal profile assure 
  profilAssureName= '';
  profilAssurePhone= '';
  profilAssureAvatar= '';
  profilMatricule = '';
  profilBeneficiaire = '';
  profilNumeroPolice= '';
  modalRef: BsModalRef;
  listMedecin: DocteurModel;

  // tslint:disable-next-line:max-line-length
  constructor(private route: ActivatedRoute, private bsService: BulletinSoinService, private adherServ: AdherentService, private fb: FormBuilder,
              private toastr: ToastrService, private medecinSrv:DocteurService, private acteService: ActeService, private modalService: BsModalService) {
                   
               }

            



   getAssure(): void {
    this.resetAlert()
    this.submitted = true;
    console.log('this.formAssure.valid ', this.formAssure.valid);
    console.log('this.formAssure.value ', this.formAssure.value);
    if (this.formAssure.valid) {
      this.loader.active = true;
      const assureinfo = Object.assign({}, this.formAssure.value);
      console.log('assureinfoPerso ', assureinfo);
      this.bsService.recherchAssure(assureinfo.categorie, assureinfo.typeAssure, assureinfo.assure).subscribe((res: any) => {
        console.log('reponse', res);
        if (res.status === 200) {
          const assures = res.assure;
          console.log('this.listAssure', assures);

          if(assures === null){
            this.activeMessage = true;
            this.erroMessage = res.message;
            this.submitted = false;
            this.loader.active = false;
          } else if ( assures.length === 0 ) {
            this.activeMessage = true;
            this.erroMessage = res.message;
            this.submitted = false;
            this.loader.active = false;
        }else if ( assures.length !== 0 ) {
          this.erroMessage = '';
          this.activeMessage = false;
         this.listAssure = assures
          
          //this.formPrestatUne.controls.nom.setValue(this.listAssure.nom);
         //this.formPrestatUne.controls.prenom.setValue(this.listAssure.prenom);
         // console.log('this.formPrestatUne.value=', this.formPrestatUne.value);
          this.submitted = false;
          this.loader.active = false;
        }

    }
  }, (error) => {
    console.error('error ', error);
    this.loader.active = false;
        }
  );}
}

getMedecin(id) {
  this.bsService.getDocteur(id).subscribe((res: any) => {
          console.log('reponse', res);
          if (res.status === 200) {
        console.log('res.status === 200');
        this.listMedecin = res.agentSante;
        console.log('this.listMedecin', this.listMedecin);
        
       /*  this.idAdherModal = this.adherent._id;
        this.adhesion = res.profileAdherent.bulletin_adhesion;
        console.log(' this.adhesion',  this.adhesion);
        this.montantAdh = this.adhesion.montantAdhesion;
        console.log(' this.adhesion.adherent.avatar', this.adhesion.adherent.avatar);
        this.avatarUrl = this.adhesion.adherent.avatar ?  API_URL +'/'+ this.adhesion.adherent.avatar : this.avatarUrl ;
        console.log(' this.avatarUrl',  this.avatarUrl); */
       }
  });
 }

      etapeUne(): void{
        console.log('this.assureSelect.hasOwnProperty(matricule)',this.assureSelect.value.hasOwnProperty('matricule'))
        if(!this.assureSelect.value.hasOwnProperty('matricule')){
       this.toastr.error(`impossible d\'avancer l\'assuré n\'a pas 
         de matricule'`,'Accès refusé');
        }else{
          this.stepForm++;
          this.stepperStatus.assure = 'stepper--horizontal--valide';
          this.stepperStatus.prestation = '';
          this.loader.active = true;
          this.acteService.getCategorie().subscribe((res: any) => {
             if (res.status === 200) {
             this.listGroupeActe = res.GroupeActes;
             this.loader.active = false;
              
             const idCentreSante = this.infoUser['centreSante']['_id'];
             console.log('idCentreSante', idCentreSante);
             this.getMedecin(idCentreSante)
             }
           });

        }
           

     }
    // tslint:disable-next-line:no-unused-expression
    etapeDeux(): void {
      console.log('etapeDeux ');
     // this.formPrestatUne.controls.userID.setValue(this.infoUser._id);
      console.log('this.formPrestatUne.valid ', this.formPrestatUne.valid);
      console.log('this.formPrestatUne.control ', this.formPrestatUne.control);
      console.log('this.formPrestatUne.value ', this.formPrestatUne.value);
     
      this.submitted = true;
      this.loader.active = true;
      if (this.formPrestatUne.valid) {
      const bulletinsoin = Object.assign({},  this.formPrestatUne.value, this.formAssure.value);
      console.log('bulletinsoin ', bulletinsoin);
      this.bsService.addBulletinSoin(bulletinsoin).subscribe((res: any) => {
        console.log('reponse', res);
        if (res.status === 200) {

          this.submitted = false;
          this.loader.active = false;
          this.stepperStatus.prestation = 'stepper--horizontal--valide';
         
          this.bsToastSuccess(res.numeroBs)
          this.annulerFormData();
          this.stepperStatus.assure = '';
          this.stepperStatus.prestation = 'stepper--horizontal--disabled';
          this.listAssure = null;
          this.stepForm = 1 ;


          this.formAssure.controls.categorie.setValue('');
          this.formAssure.controls.typeAssure.setValue('');


    }
  },error=>{
    console.log('error',error)
    this.loader.active = false;
    //this.toastr.error(error.error.message)
  });
      // this.stepperStatus.piece_ident = 'stepper--horizontal--valide';
      // this.stepperStatus.situation = '';
      // this.submitted = false;

    }


  }
  bsToastSuccess(numBs){
    
    let messageHtml = `<div>Bulletin de soin créé.</div>
                             <br/>
                      <div class='btn-pile' >
                            <span> Numéro BS: ${numBs}<span>
                        </div> `
    this.toastr.success(messageHtml, 'Succes!', {
      timeOut: 150000,
      enableHtml: true,
      closeButton: true,
    });
    

    
  }
/*   etapeTrois(): void {

    this.submitted = true;
    if (this.formAdherent.situation.valid) {
      const adherent = Object.assign({}, this.formAdherent.info_perso.value,
        this.formAdherent.piece_ident.value, this.formAdherent.situation.value);
      adherent.dateNaissance = this.dateNaissMatch;
      adherent.pieceIdnt =  this.piecidentcheck;
      adherent.dateEtabli =  this.dateEtabliMatch;
      console.log('adherent ', adherent);
      this.stepperStatus.situation = 'stepper--horizontal--valide';
      this.loader.active = true;
      this.adherServ.Enregistrer(adherent).subscribe((res: any) => {
        console.log('reponse', res);
        if (res.status === 200) {
         console.log('res.status === 200');
         this.loader.active = false;
         this.submitted = false;
         this.stepForm = 1;
         this.stepperStatus.info_perso = '';
         this.stepperStatus.piece_ident = 'stepper--horizontal--disabled';
         this.stepperStatus.situation = 'stepper--horizontal--disabled';
         this.annulerFormData();
         this.showSuccess();

                     }
      });

    }


  } */

annulerFormData(): void {
 // this.formAdherent.reset();
 this.formAssure.reset();
 // this.formBulletinSoin.reset();
 this.formPrestatUne.controls.montantBrut.setValue('');

 this.formPrestatUne.controls.taux.setValue('');

 this.formPrestatUne.controls.ticket.setValue('');

 this.formPrestatUne.controls.montantPaye.setValue('');
 //this.formPrestatUne.controls.userID.setValue('');

}
annulerFormDataInfoperso() {

  this.formAdherent.info_perso.reset() ;
}
precedent() {
  this.stepForm--;
}
/* onDatepickerChange(e, typeDate) {

   const datePick = e ;
   if ( typeDate === 'dateNaiss') {
    this.dateNaissMatch =  FormatDate.getFormatDate('yy-mm-dd', datePick);
    console.log('this.dateNaissMatch', this.dateNaissMatch );
  } else if (typeDate === 'dateEtabli') {

    console.log('onDatepickerChange', e);
    // this.formAdherent.piece_ident.get('dateEtabli').patchValue(FormatDate.getFormatDate('yy-mm-dd', new Date()));
    this.dateEtabliMatch = FormatDate.getFormatDate('yy-mm-dd', datePick);
    console.log('this.dateEtabliMatch', this.dateEtabliMatch);
  }


} */

/* onCheckboxChange(e) {

  if (e.target.checked) {
    console.log('onCheckboxChange', e.target.value);

    this.checkboxes.forEach((item, index) => {
      if (item.value !== e.target.value) {
        // tslint:disable-next-line:no-unused-expression
        item.checked = false;

        // console.log('checkboxes item', item.value);
        // console.log('checkboxes', this.checkboxes);
      } else {
        item.checked = true;
        this.piecidentcheck = e.target.value;
      }

    });
  }

} */

inputCategChange(itemValue) {

  console.log('itemValue', itemValue);

  if (itemValue === 'numero') {
    this.categorieRecher = 'numéro téléphone';
    this.inputPlaceholderSearch = `Saisir  le  ${this.categorieRecher} de l\'assuré`;
  } else if (itemValue === 'matricule') {
    this.categorieRecher = 'matricule';
    this.inputPlaceholderSearch = `Saisir  le ${this.categorieRecher} de l\'assuré`;
  }

}
inputTypeChange(itemValue) {

  console.log('itemValue', itemValue);

  if (itemValue === 'assure_principal') {
    this.typeAssure = 'assuré principal';
    this.inputPlaceholderSearch = `Saisir  le ${this.categorieRecher} de l\'assuré`;
  } else if (itemValue === 'beneficiaire') {
    this.typeAssure = 'beneficiaire';
    this.inputPlaceholderSearch = `Saisir  le ${this.categorieRecher} du de beneficiaire`;
  }

}
getacteChange(itemValue) {
  console.log('itemValue', itemValue);
  this.loader.active = true;
  this.acteService.getActesByGroup(itemValue).subscribe((res: any) => {
    console.log('reponse', res);
    if (res.status === 200) {
            this.listActe = res.actes;
            this.loader.active = false;
              }
            });

}
selectItemActeChange(acte) {
  console.log('acte', acte);
  this.itemActe = this.listActe.filter( item => {
     return item.slug === acte;
});

  console.log('itemActe', this.itemActe);
  if (this.itemActe) {

  //  this.formPrestatUne.controls.montantBrut.value = this.itemActe[0].montantBrut;
    this.formPrestatUne.controls.montantBrut.setValue(this.itemActe[0].montantBrut);
   // this.formPrestatUne.controls.taux.value = this.itemActe[0].tauxPrive;
   let etablissement = this.infoUser['centreSante']['etablissement'] 
   console.log('etablissement=', etablissement);
  
    this.formPrestatUne.controls.taux.setValue(
    etablissement == 'public' ? this.itemActe[0].tauxPublic : this.itemActe[0].tauxPrive);
   // this.formPrestatUne.controls.ticket.value = this.itemActe[0].ticket;
    this.formPrestatUne.controls.ticket.setValue(this.itemActe[0].ticket);
   // this.formPrestatUne.controls.montantPaye.value = this.itemActe[0].montantBrut * (this.itemActe[0].ticket / 100);
  // Montant couvert = Montant total * (Taux de couverture / 100)
  let montant_couvrt =  this.itemActe[0].montantBrut * (etablissement == 'public' ? (this.itemActe[0].tauxPublic/100) : (this.itemActe[0].tauxPrive/100))
   let montant_util = this.itemActe[0].montantBrut - montant_couvrt ;
   console.log('montant_util=', montant_util)
   this.formPrestatUne.controls.montantPaye.setValue(montant_util);
   this.formPrestatUne.controls.nom.setValue(this.assureSelect.value.nom)
   this.formPrestatUne.controls.prenom.setValue(this.assureSelect.value.prenom)
   this.formPrestatUne.controls.matricule.setValue(this.assureSelect.value.matricule)
   
    console.log(' this.formPrestatUne.controls', this.formPrestatUne.controls);
    console.log(' this.formPrestatUne.value', this.formPrestatUne.value);
  }

}

//
onClientSelectionChange(value: any,event): void {
  console.log('event',event);
  let ischecked = event.target.checked
  console.log('ischecked',ischecked);
  if(ischecked){
    console.log('value',value);
    this.assureSelect.setValue(value);
    // Utilisez les données du client sélectionné comme vous le souhaitez
    console.log('this.assureSelect',this.assureSelect.value);
  }else{
    this.assureSelect.reset()
    console.log('reset this.assureSelect',this.assureSelect.value);
  }

}

closeAlert(data: boolean){
  console.log('closeAlert',data)
    this.activeMessage =data;
}
  ngOnInit(): void {
    // information utilisteur
    this.infoUser = localStorage.getItem('user_centre_mugasci');
    this.infoUser = JSON.parse( this.infoUser);

    
    this.formAssure = this.fb.group({
      categorie: ['', [Validators.required, Validators.minLength(2)]],
      assure: ['', [Validators.required, Validators.minLength(2),
         Validators.pattern(ValidationService.SpecialChartRegex)]],
      typeAssure: ['', [Validators.required, Validators.minLength(2)]],

  });

    this.formPrestatUne =  this.fb.group({
      medecin:['', [Validators.required ]],
      groupeActe: ['', [Validators.required]],
    acte: ['', [Validators.required ]],
    montantPaye: ['', [Validators.required]],
    montantBrut: ['', [Validators.required]],
    taux: ['', [Validators.required]],
    ticket: ['', [Validators.required]],
    nom: ['', [Validators.required]],
    prenom: ['', [Validators.required]],
    matricule: ['', [Validators.required]],
     centre: [this.infoUser['centreSante']['_id'], [Validators.required]],
});








  }

  showSuccess() {
    
  }


  // ereur message

  getErrorMessage(status, message) {
    if ( status === null ) {
        this.activeMessage = true;
        return this.erroMessage = message;
    }
  }

  resetAlert(){
    this.erroMessage ='';
  this.activeMessage = false;
  }

  // modal
openModalTemplate(template: TemplateRef<any>, userData): void{
  // this.openModal(template);
  this.profilAssureName = userData.nom +' '+ userData.prenom;
  this.profilAssurePhone = userData.telephone;
  this.profilAssureAvatar = userData.avatar;
  this.profilMatricule = userData.matricule;
  this.profilNumeroPolice =  userData.numeroPolice
 // this.profilBeneficiaire = userData.beneficiaire;
  this.modalRef = this.modalService.show(template);
}
hideModalTemplate(){
  this.modalRef.hide();
}

}
function etapeDeux() {
  throw new Error('Function not implemented.');
}


