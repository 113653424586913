import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserModel } from 'src/app/shared/models/user.model';
import { environment } from 'src/environments/environment';

const API_URL = environment.backend_url;
@Injectable({
  providedIn: 'root'
})
export class LoginService {
// Http Options
httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};
  constructor(private httpClient: HttpClient, private route: Router) { }
  isLoggedIn() {
    return localStorage.getItem('accessToken') != null;

  }
  GetToken() {
    return localStorage.getItem('token') || '';
   }
   GetAccessToken() {
    return localStorage.getItem('accessToken') || '';
   }
   getAuthToken() {
    return localStorage.getItem('authToken') || '';
   }
   getRole() {
    const role = localStorage.getItem('mugasci-role');

    return role;
  }

  generateTokenAuth(){
    return this.httpClient.post(`${API_URL}/api/generatetoken`,{
      apikey: "p0uv017rlk57",
    }, this.httpOptions)
  }

  connexion(authToken,user: UserModel): Observable<UserModel> {
    console.log('accessToken',authToken)
    const  httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authorization': `Bearer ${authToken}`
      }),
    }; 
    
    return this.httpClient.post<UserModel>(`${API_URL}/util/centre/connexion`, user, httpOptions);
  }
  deconnexion(){
    localStorage.removeItem('accessToken');
    localStorage.removeItem('authToken')
    localStorage.removeItem('user_centre_mugasci')
    localStorage.removeItem('mugasci-role')
    this.route.navigate(['/connexion']);
  }
  isAuthorized(role: Array<any>) {

    if (role.indexOf(this.getRole()) === -1 ) {

      return false;
    } else {
      return true;
    }


  }
}
