import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-elegant-alerts-notices',
  templateUrl: './elegant-alerts-notices.component.html',
  styleUrls: ['./elegant-alerts-notices.component.scss']
})
export class ElegantAlertsNoticesComponent implements OnInit {
  @Input() message: string;
  constructor() { }

  ngOnInit(): void {
  }

}
