import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { FormatDate } from 'src/app/core/patterns/FormatDate';
import { ActeService } from 'src/app/core/services/acte.service';
import { AdherentService } from 'src/app/core/services/adherent.service';
import { BulletinSoinService } from 'src/app/core/services/bulletin-soin.service';
import { ActeModel } from 'src/app/shared/models/acte.model';
import { BulletinSoin } from 'src/app/shared/models/bulletinsoin.model';
import { GroupeActeModel } from 'src/app/shared/models/groupeacte.model';

@Component({
  selector: 'app-consult-ordonnance',
  templateUrl: './consult-ordonnance.component.html',
  styleUrls: ['./consult-ordonnance.component.scss']
})
export class ConsultOrdonnanceComponent implements OnInit {

  modalRef: BsModalRef;
  formRechercheBS = null;
  formPrestatUne = null;
  formBulletinSoin = null;
  listBulletinSoin: BulletinSoin[] ;
  listGroupeActe: GroupeActeModel[];
  listActe: ActeModel[];
  listOrdonnance = [];
  itemActe = null;
  idBS = 'brajo koko';
  public formAdherent = {

    info_perso: null,
    piece_ident: null,
    situation: null
  } ;
  public stepForm = 1 ;
  loader = {active: false};
  submitted = false;
  checkboxes: Array<any> = [
      {
      name: 'CNI',
      value: 'CNI',
      checked: false
    }, {
      name: 'Passport',
      value: 'Passport',
      checked: false
    }, {
      name: 'Autre',
      value: 'Autre',
      checked: false
    }
];
inputPlaceholderSearch = 'Choisir une categorie';
erreurRangeDate = '';
dateFin = new Date() ;
dateDebut = new Date() ;
stepperStatus = {
  assure: '',
  prestation: 'stepper--horizontal--disabled',
  info_perso: '',
  piece_ident: 'stepper--horizontal--disabled',
    situation: 'stepper--horizontal--disabled'
};
infoUser: any;
  // tslint:disable-next-line:max-line-length
  constructor(private route: ActivatedRoute, private bsService: BulletinSoinService, private adherServ: AdherentService, private fb: FormBuilder,
              private toastr: ToastrService, private acteService: ActeService, private modalService: BsModalService,
              private router: Router) {
                this.infoUser = localStorage.getItem('user_centre_mugasci');
                this.infoUser = JSON.parse( this.infoUser);
                  console.log('this.infoUser', this.infoUser);      

               }

               RechercherBS(): void {
                this.submitted = true;
                if (this.dateDebut >  this.dateFin){
                  this.erreurRangeDate = 'la date de début doit etre inférieur à la date de fin';
                }
                console.log('this.formRechercheBS.valid ', this.formRechercheBS.valid);
                console.log('this.formRechercheBS.value ', this.formRechercheBS.value);
                if (this.formRechercheBS.valid && this.dateDebut < this.dateFin) {
                   const dateDebut =  FormatDate.getFormatDate('yy-mm-dd',  this.dateDebut);
                  // this.formRechercheBS.controls.dateDebut.setValue(dateDebut);
                   const dateFin = FormatDate.getFormatDate('yy-mm-dd', this.dateFin);
                  // this.formRechercheBS.controls.dateFin.setValue(dateFin);
                   this.loader.active = true;
                   const dateRange = Object.assign({}, this.formRechercheBS.value);
                   dateRange.dateDebut = FormatDate.getFormatDate('yy-mm-dd',  this.dateDebut);
                   dateRange.dateFin = FormatDate.getFormatDate('yy-mm-dd',  this.dateFin);
                   console.log('dateRange ', dateRange);
                   this.bsService.recherchBSByDate(dateRange.centre,dateRange.dateDebut, dateRange.dateFin).subscribe((res: any) => {
                    console.log('reponse', res);
                    if (res.status === 200) {
                      this.listBulletinSoin = res.bulletinSoins;
                      this.submitted = false;
                      this.loader.active = false;
                }
              });
            
              }
            }
            
                  etapeUne(): void{
                       this.stepForm++;
                       this.stepperStatus.assure = 'stepper--horizontal--valide';
                       this.stepperStatus.prestation = '';
                       this.loader.active = true;
                       this.acteService.getCategorie().subscribe((res: any) => {
                          if (res.status === 200) {
                          this.listGroupeActe = res.GroupeActes;
                          this.loader.active = false;
                          }
                        });
            
            
                 }
                 // EMIT EVENT
                // tslint:disable-next-line:no-unused-expression
                /* onloaderAdded(eventData: { active: boolean }){
                  console.log('onloaderAdd', eventData.active);
                  this.loader.active = eventData.active;
                } */
                onbsAdded(eventData: { data: Array<any> }){
                  console.log('onloaderAdd', eventData.data);
                  this.showSuccess();
                  this.hideModal();
                }
              getSlugBS(slug, template: TemplateRef<any>): void {
               console.log('getSlugBS', slug);
               this.idBS = slug;
               // this.openModal(template);
               this.modalRef = this.modalService.show(template);
              }
              // modal affection
              /* openModalaff(slug){
                this.idBS = slug;
                this.modalRef = this.modalService.show(ModalAffectionComponent,
                  { initialState: { slug }, ignoreBackdropClick: true, animated: true, keyboard: true, class: 'modal' });
              }
              */
            annulerFormData(): void {
             // this.formAdherent.reset();
             this.formRechercheBS.reset();
             // this.formBulletinSoin.reset();
             this.formPrestatUne.controls.montantBrut.setValue('');
            
             this.formPrestatUne.controls.taux.setValue('');
            
             this.formPrestatUne.controls.ticket.setValue('');
            
             this.formPrestatUne.controls.montantPaye.setValue('');
            
            }
            annulerFormDataInfoperso() {
            
              this.formAdherent.info_perso.reset() ;
            }
            precedent() {
              this.stepForm--;
            }
             onDatepickerChange(e, typeDate) {
               const datePick = e ;
               console.log('datePick', datePick);
               if ( typeDate === 'dateDebut') {
                this.dateDebut = new Date(datePick);
               // this.dateDebut =  FormatDate.getFormatDate('yy-mm-dd', datePick);
                console.log('this.dateDebut', this.dateDebut );
              } else if (typeDate === 'dateFin') {
                this.dateFin = new Date(datePick);
                // this.formAdherent.piece_ident.get('dateEtabli').patchValue(FormatDate.getFormatDate('yy-mm-dd', new Date()));
               // this.dateFin = FormatDate.getFormatDate('yy-mm-dd', datePick);
                console.log('this.dateFin', this.dateFin);
              }
            
            }
            
             ajouterordonnance(InfoBS) {
              console.log('Info Bulletin Soin', InfoBS);
              // emit save data
             // this.bsService.dataBulletinSoin(InfoBS);
              localStorage.setItem('bulletin-soin', JSON.stringify(InfoBS) );
              this.router.navigate([`pharmacie/details-ordonnance`]);
            }
            
            inputCategChange(itemValue) {
            
              console.log('itemValue', itemValue);
            
              if (itemValue === 'numero') {
                this.inputPlaceholderSearch = 'Saisir  le numéro de telephone de l\'assuré';
              } else if (itemValue === 'matricule') {
                this.inputPlaceholderSearch = 'Saisir  le matricule de telephone de l\'assuré';
              }
            
            }
            inputTypeChange(itemValue) {
            
              console.log('itemValue', itemValue);
            
              if (itemValue === 'assure_principal') {
                this.inputPlaceholderSearch = 'Saisir  le numéro de telephone de l\'assuré';
              } else if (itemValue === 'beneficiaire') {
                this.inputPlaceholderSearch = 'Saisir  le matricule de telephone de l\'assuré';
              }
            
            }
            getacteChange(itemValue) {
              console.log('itemValue', itemValue);
              this.loader.active = true;
              this.acteService.getActesByGroup(itemValue).subscribe((res: any) => {
                console.log('reponse', res);
                if (res.status === 200) {
                        this.listActe = res.actes;
                        this.loader.active = false;
                          }
                        });
            
            }
            selectItemActeChange(acte) {
              console.log('acte', acte);
              this.itemActe = this.listActe.filter( item => {
                 return item.slug === acte;
            });
            
              console.log('itemActe', this.itemActe);
              if (this.itemActe) {
            
              //  this.formPrestatUne.controls.montantBrut.value = this.itemActe[0].montantBrut;
                this.formPrestatUne.controls.montantBrut.setValue(this.itemActe[0].montantBrut);
               // this.formPrestatUne.controls.taux.value = this.itemActe[0].tauxPrive;
                this.formPrestatUne.controls.taux.setValue(this.itemActe[0].tauxPrive);
               // this.formPrestatUne.controls.ticket.value = this.itemActe[0].ticket;
                this.formPrestatUne.controls.ticket.setValue(this.itemActe[0].ticket);
               // this.formPrestatUne.controls.montantPaye.value = this.itemActe[0].montantBrut * (this.itemActe[0].ticket / 100);
                this.formPrestatUne.controls.montantPaye.setValue(this.itemActe[0].montantBrut * (this.itemActe[0].ticket / 100))
                console.log(' this.formPrestatUne.controls', this.formPrestatUne.controls);
                console.log(' this.formPrestatUne.controls', this.formPrestatUne.value);
              }
            
            }
              ngOnInit(): void {
                this.formRechercheBS = this.fb.group({
                  centre:[this.infoUser['centreSante']['_id'], [Validators.required,] ],
                  dateDebut: ['', [Validators.required,]],
                  dateFin: ['', [Validators.required,]],
              });
                this.formPrestatUne =  this.fb.group({
                 
                  groupeActe: ['', [Validators.required, Validators.minLength(2)]],
                acte: ['', [Validators.required, Validators.minLength(2)]],
                montantPaye: ['', [Validators.required, Validators.minLength(2)]],
                montantBrut: ['', [Validators.required, Validators.minLength(2)]],
                taux: ['', [Validators.required, Validators.minLength(2)]],
                ticket: ['', [Validators.required, Validators.minLength(2)]],
            });
            
            
           
            
              }
            
              // modal
            
              openModal(template: TemplateRef<any>) {
                this.modalRef = this.modalService.show(template);
              }
                hideModal() {
                this.modalRef.hide();
              } 
            
              showSuccess() {
                this.toastr.success('Ajout d\'un Affection.', 'Ajouter avec succes!');
              }

}
