import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss']
})
export class ListItemComponent implements OnInit {
  @Input() message: string;
  @Input() alertDanger:boolean=false; 
  @Input() alertIcon:string;
  @Output() closeItemEvent = new EventEmitter<boolean>();
  constructor() { }

  closeAlert(){
    this.closeItemEvent.emit(false);
  }
  ngOnInit(): void {
  }

}
