import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CentreSanteModel } from 'src/app/shared/models/centre_sante.model';
import { CommuneModel } from 'src/app/shared/models/commune.model';
import { DocteurModel } from 'src/app/shared/models/docteur.model';
import { VilleModel } from 'src/app/shared/models/ville.model';

import { environment } from 'src/environments/environment';


const API_URL = environment.backend_url;

@Injectable({
  providedIn: 'root'
})
export class LocalisationService {

  // Http Options
 httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

  constructor(private httpClient: HttpClient) { }


 // Enregistrer centre santé

 addCentreSante(centre: CentreSanteModel): Observable<CentreSanteModel> {

  return this.httpClient.post<CentreSanteModel>(`${API_URL}/centre-sante/enregistrer`, centre, this.httpOptions
  );
 }
 // Enregistrer centre santé

 addMedecinCentreSante(centre: DocteurModel): Observable<DocteurModel> {

  return this.httpClient.post<DocteurModel>(`${API_URL}/centre-sante/agent-sante/enregistrer`, centre, this.httpOptions
  );
 }
 
 // Liste centre sante
 getCentreSante(){
  return this.httpClient.get<CentreSanteModel[]>(`${API_URL}/centre-sante/list`);
 }
  // Liste ville
  getVille(): Observable<VilleModel[]> {

    return this.httpClient.get<VilleModel[]>(`${API_URL}/ville/list`);
  }
// Liste ville
getCommuneByVille(id): Observable<CommuneModel[]> {

  return this.httpClient.get<VilleModel[]>(`${API_URL}/commune/ville/list/${id}`);
}

// Profile centre
getProfileCentreSante(id): Observable<CentreSanteModel[]> {

  return this.httpClient.get<CentreSanteModel[]>(`${API_URL}/centre-sante/${id}`);
}

}
