import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BulletinSoin } from 'src/app/shared/models/bulletinsoin.model';
import { DocteurModel } from 'src/app/shared/models/docteur.model';
import { MedicamentModel } from 'src/app/shared/models/medicaments.model';
import { environment } from 'src/environments/environment';

const API_URL = environment.backend_url;
@Injectable({
  providedIn: 'root'
})
export class BulletinSoinService {
  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  private _dataBulletinSoin= new Subject<any>();
  constructor(private httpClient: HttpClient) { }


//  enregistrer Bulletin de soin
addBulletinSoin(bs: BulletinSoin): Observable<BulletinSoin> {

  return this.httpClient.post<BulletinSoin>(`${API_URL}/bulletin_soin/enregistrer`, bs, this.httpOptions
  );
  }

  //  enregistrer au Bulletin de soincode affection
addBSCodeAff(code: BulletinSoin): Observable<BulletinSoin> {

  return this.httpClient.post<BulletinSoin>(`${API_URL}/bulletin_soin/enregistrer/affection`, code, this.httpOptions
  );
  }

  // Recherche Assure
recherchAssure(cate, type, assure): Observable<BulletinSoin[]> {

  return this.httpClient.get<BulletinSoin[]>(`${API_URL}/recherchAssure/${cate}/${type}/${assure}`);
}

  // Liste Docteurs
  getDocteur(id): Observable<DocteurModel[]> {

    return this.httpClient.get<DocteurModel[]>(`${API_URL}/centre-sante/medecin/list/${id}`);
  }

// Recherche Medicament
recherchMedicamen(texte): Observable<MedicamentModel[]> {

  return this.httpClient.get<MedicamentModel[]>(`${API_URL}/bulletin_soin/search/medicament/${texte}`);
}


  // Recherche Bulletin soin
  recherchBSByDate(centre,dateD, dateF): Observable<BulletinSoin[]> {

    return this.httpClient.get<BulletinSoin[]>(`${API_URL}/bulletin_soin/filtre-by-date/${centre}/${dateD}/${dateF}`);
  }
  recherchBSByDateMat(centre,dateD, matricule): Observable<BulletinSoin[]> {

    return this.httpClient.get<BulletinSoin[]>(`${API_URL}/bulletin_soin/filtre-by-item/${centre}/${dateD}/${matricule}`);
  }
  modifierOrdonnance(bs): Observable<BulletinSoin[]> {
    return this.httpClient.put<BulletinSoin[]>(`${API_URL}/bulletin_soin/modifier/ordonnance`, bs, this.httpOptions);
  }

  // emit
  dataBulletinSoin(event) {
    this._dataBulletinSoin.next(event);
  }

  get dataBulletinSoin$ () {
    return this._dataBulletinSoin.asObservable();
  }
}
