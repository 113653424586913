import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, } from '@angular/common/http';

import { Observable } from 'rxjs';
import { DocteurModel } from 'src/app/shared/models/docteur.model';

const API_URL = environment.backend_url;


@Injectable({
  providedIn: 'root'
})
export class DocteurService {
  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private httpClient: HttpClient) { }



  // Liste Docteurs
getDocteur(id): Observable<DocteurModel[]> {

  return this.httpClient.get<DocteurModel[]>(`${API_URL}/centre-sante/medecin/list/${id}`);
}

// ajouter Docteur
addDocteur(docteur: DocteurModel): Observable<DocteurModel> {

  return this.httpClient.post<DocteurModel>(`${API_URL}/centre-sante/medecin/enregistrer`, docteur, this.httpOptions
  );
}

// editer Docteur
editerDocteur(id,docteur: DocteurModel): Observable<DocteurModel> {

  return this.httpClient.put<DocteurModel>(`${API_URL}/centre-sante/medecin/editer/${id}`, docteur, this.httpOptions
  );
}

// supprimer Docteur
deleteDocteur(id: string): Observable<DocteurModel> {

  return this.httpClient.delete<DocteurModel>(`${API_URL}/centre-sante/medecin/supprimer/${id}`);

}

}
