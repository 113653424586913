import { ValidatorFn } from "@angular/forms";

export class FormatDate {
  static  months = [{
    title: 'Janvier',
    date: 0
  }, {
    title: 'Fevrier',
    date: 1
  }, {
    title: 'Mars',
    date: 2
  }, {
    title: 'Avril',
    date: 3
  }, {
    title: 'Mai',
    date: 4
  },{
    title: 'Juin',
    date: 5
  }, {
    title: 'Juillet',
    date: 6
  }, {
    title: 'Aout',
    date: 7
  }, {
    title: 'Septembre',
    date: 8
  }, {
    title: 'Octobre',
    date: 9
  }, {
    title: 'Novembre',
    date: 10
  }, {
    title: 'Decembre',
    date: 11
  }
];
   static  getFormatDate = (expr, date) => {

    if (expr === 'yy-mm-dd') {
        const d = new Date(date);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        const year = d.getFullYear();
        if (month.length < 2) { month = '0' + month; }
        if (day.length < 2) { day = '0' + day; }
        return [year, month, day].join('-');
    }
   }
   static dateRangeValidator(min: Date, max: Date): ValidatorFn {
    return control => {
        console.log('control.value', control.value);
        if (!control.value) { return null; }

        const dateValue = new Date(control.value);
        console.log('min', min);
        console.log('max', max);
        if (min && dateValue < min) {
        return { message: 'error message' };
      }

        if (max && dateValue > max) {
        return { message: 'error message' };
      }

      // tslint:disable-next-line:no-unused-expression
        null;
    };
  }

  static ListMonths = ()=>{
    const months = [{
      title: 'janvier',
      date: 0
    }, {
      title: 'fevrier',
      date: 1
    }, {
      title: 'mars',
      date: 2
    }, {
      title: 'avril',
      date: 3
    }, {
      title: 'mai',
      date: 4
    },{
      title: 'juin',
      date: 5
    }, {
      title: 'juillet',
      date: 6
    }, {
      title: 'aout',
      date: 7
    }, {
      title: 'septembre',
      date: 8
    }, {
      title: 'octobre',
      date: 9
    }, {
      title: 'novembre',
      date: 10
    }, {
      title: 'decembre',
      date: 11
    }
  ];
    return months;
  };

  static ListYears = () => {
    const currentYear = new Date().getFullYear();
    const beginYear = 2021;
    const yearList = [];

    for (let year = currentYear; year >= beginYear; year--) {
          yearList.push(year.toString());
      }
    console.log('yearList', yearList);
    return yearList.reverse();

};
// tslint:disable-next-line:align
static getMonthNumber = (monthName) => {
  switch (monthName.toLowerCase()) {
    case 'janvier':
      return 0;
    case 'fevrier':
      return 1;
    case 'mars':
      return 2;
    case "avril":
      return 3;
    case "mai":
      return 4;
    case "juin":
      return 5;
    case "juillet":
      return 6;
    case "aout":
      return 7;
    case 'septembre':
      return 8;
    case "octobre":
      return 9;
    case "novembre":
      return 10;
    case "decembre":
      return 11;
    default:
      return -1; // Return -1 if the month name is not valid
  }
}
}
