import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AdherentService } from 'src/app/core/services/adherent.service';
import { BulletinSoinService } from 'src/app/core/services/bulletin-soin.service';

@Component({
  selector: 'app-modal-matricule',
  templateUrl: './modal-matricule.component.html',
  styleUrls: ['./modal-matricule.component.scss']
})
export class ModalMatriculeComponent implements OnInit {

  @Input() idAdherent: string;
  @Output()loaderCreated = new EventEmitter<{ active: boolean }>();
  @Output()bulletinAdhesion = new EventEmitter<{data: any  }>();
  @Output()hideModalMatri = new EventEmitter<{data: string  }>();

 // @ViewChild('addMatricule') public modalTemplateRef: TemplateRef<any>;
  
  bookTitle: string;
  formMatricule;
  submitted = false;
   bsModalRef: BsModalRef;
   
  constructor(private fb: FormBuilder, private adherent: AdherentService) { }


  ajouterMatricule(){
    this.submitted = true;
    console.log('this.idAdherent', this.idAdherent);
    this.formMatricule.controls.idAdh.setValue(this.idAdherent);
    if (this.formMatricule) {
    this.loaderCreated.emit({ active: true });
    console.log(' this.idAdherent', this.idAdherent);


    const formMat = Object.assign({}, this.formMatricule.value);
    console.log('formAff ', formMat);
    this.adherent.modifierMatricule(formMat).subscribe((res: any) => {
     console.log('reponse', res);
     if (res.status === 200) {
      const ba = res.Bulletinadhesion;
      this.bulletinAdhesion.emit({ data: ba });
      this.submitted = false;
      this.loaderCreated.emit({ active: false });
      this.hideModal('matricule');
 }
}); 
    }

  }

  ngOnInit(): void {

     // form validateur
     this.formMatricule = this.fb.group({
      matricule: ['', [Validators.required, Validators.minLength(2)]],
       idAdh: ['', [Validators.required, Validators.minLength(2)]],
     /* specialite: ['', [Validators.required, Validators.minLength(2)]],
      telephone: ['', [Validators.required, Validators.minLength(2)]],
      email: ['', [Validators.pattern(ValidationService.emailRegex)]],
      genre: [''],
      website: [''],
      description: [''], */
    });
  }


  hideModal(template){
   // bsModalRef.hide();
    this.annulerFormData();
    if ( template === 'matricule' ) {
      this.hideModalMatri.emit({data: 'hide-modalMatricule'});
    } 

  }

  annulerFormData(): void {
   
    this.formMatricule.reset();
   
    //this.formMatricule.controls.idAdh.setValue('');
   
   
   
   }

}
