import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProduitModel } from 'src/app/shared/models/produit';
import { environment } from 'src/environments/environment';

const API_URL = environment.backend_url;

@Injectable({
  providedIn: 'root'
})
export class ProduitService {
// Http Options
httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

constructor(private httpClient: HttpClient) { }




// Liste adherent
getProduit(): Observable<ProduitModel[]> {

  return this.httpClient.get<ProduitModel[]>(`${API_URL}/produit/list`);
}

// pre enregistrer adherent
addProduit(produit: ProduitModel): Observable<ProduitModel> {

return this.httpClient.post<ProduitModel>(`${API_URL}/produit/enregistrer`, produit, this.httpOptions
);
}

}
