import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-infos-bulletin',
  templateUrl: './infos-bulletin.component.html',
  styleUrls: ['./infos-bulletin.component.scss']
})
export class InfosBulletinComponent implements OnInit {

  @Input () prestations: object;
  @Input() numero: string;

  @Output()hideModal = new EventEmitter<{data: string  }>();
  constructor() { }

  hideModalBs(){
       this.hideModal.emit({data: 'hide-modal'});
 
   }

  ngOnInit(): void {
  }

}
