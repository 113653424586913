import { FormGroup } from "@angular/forms";

export class ValidationService {

  static emailRegex = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';

  static numberMobilDigit = '^((\\+91-?)|0)?[0-9]{10}$';
  // interdire les caractères spéciaux
  static SpecialChartRegex = "^[a-zA-Z0-9]*$"
  
  static passwordMatchValidator(form: FormGroup) {
    const password = form.get('password').value;
    const confirmPassword = form.get('confirmPassword').value;

    if (password !== confirmPassword) {
     form.get('confirmPassword').setErrors({ mismatch: true });
   } else {
     form.get('confirmPassword').setErrors(null);
   }
 }
}
