import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ValidationService } from 'src/app/core/patterns/ValidationService';
import { DocteurService } from 'src/app/core/services/docteur.service';
import { environment } from 'src/environments/environment';


import { Store } from '@ngrx/store';
//import * as  fromRoot from '../../../../../core/store';
//import * as fromCentres from '../../../../core/store/adherents'
const API_URL = environment.backend_url;

@Component({
  selector: 'app-medecins',
  templateUrl: './medecins.component.html',
  styleUrls: ['./medecins.component.scss']
})
export class MedecinsComponent implements OnInit {


  apiUrl = API_URL;
  submitted: boolean = false;
  formMedecin: any;
  idCentreSante: string = '';
  idMedecin: string = '';
  medecins: any;
  infoUser: string;

  loader = {active: false};
  modalRef: BsModalRef;
  constructor(private modalService: BsModalService,private route: ActivatedRoute , /* private toastr: ToastrService, private store: Store<fromRoot.State>, */
    private router: Router,private fb: FormBuilder, private medecinSrv:DocteurService,private toastr: ToastrService,) {

         

     }



    getMedecin(id) {
      this.medecinSrv.getDocteur(id).subscribe((res: any) => {
              console.log('reponse', res);
              if (res.status === 200) {
            console.log('res.status === 200');
            this.medecins = res.agentSante;
            console.log('this.centreSante', this.medecins);
            
           /*  this.idAdherModal = this.adherent._id;
            this.adhesion = res.profileAdherent.bulletin_adhesion;
            console.log(' this.adhesion',  this.adhesion);
            this.montantAdh = this.adhesion.montantAdhesion;
            console.log(' this.adhesion.adherent.avatar', this.adhesion.adherent.avatar);
            this.avatarUrl = this.adhesion.adherent.avatar ?  API_URL +'/'+ this.adhesion.adherent.avatar : this.avatarUrl ;
            console.log(' this.avatarUrl',  this.avatarUrl); */
           }
      });
     }


  ngOnInit(): void {

    this.infoUser = localStorage.getItem('user_centre_mugasci');
    this.infoUser = JSON.parse( this.infoUser);
      console.log('this.infoUser', this.infoUser);
      const idCentreSante = this.infoUser['centreSante']['_id'];
      console.log('idCentreSante', idCentreSante);
      this.getMedecin(idCentreSante);

    this.formMedecin = this.fb.group({
      nom: ['', [Validators.required, Validators.minLength(2)]],
      prenom: ['', [Validators.required, Validators.minLength(2)]],
       telephone: ['', [Validators.required, Validators.minLength(2),
        Validators.pattern(ValidationService.numberMobilDigit)]],
       email: ['' , [Validators.pattern(ValidationService.emailRegex)] ],
      centreSante: [idCentreSante, [Validators.required]],
      fonction: ['', [Validators.required, Validators.minLength(2)]],
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  openModalEditMedecin(item,template: TemplateRef<any>) {
    console.log('item',item)
    this.formMedecin.get('nom').setValue(item.nom);
    this.formMedecin.get('prenom').setValue(item.prenom);
    this.formMedecin.get('telephone').setValue(item.telephone);
    this.formMedecin.get('fonction').setValue(item.fonction);
    this.idMedecin = item._id;
    this.formMedecin.get('email').setValue(item.hasOwnProperty('email') ? item.email : '' );
    this.modalRef = this.modalService.show(template);
    

  }
  annulerFormData(form): void {
    form.reset();

  }
  resetFormValidations(form:String) {

    if(form=='formMedecin'){
      Object.keys(this.formMedecin.controls).forEach(controlName => {
        this.formMedecin.get(controlName).markAsUntouched();
        this.formMedecin.get(controlName).markAsPristine();
      });
    }
    
  }

  ajoutMedecin(){

    this.submitted = true;
    console.log('this.formMedecin', this.formMedecin);
    console.log('this.formMedecin valid', this.formMedecin.valid);
    const AgentSante = Object.assign({}, this.formMedecin.value);
   // medecin.centreSante = this.idCentreSante ;
    console.log('AgentSante', AgentSante);
    if (this.formMedecin.valid) {

      this.loader.active = true;
      this.medecinSrv.addDocteur(AgentSante).subscribe((res: any) => {

        console.log('reponse', res);
        if (res.status === 200) {
         console.log('res.status === 200');
         this.loader.active = false;
       this.medecins = res.agentSante;
         this.annulerFormData(this.formMedecin);
         this.resetFormValidations('formMedecin');
         this.modalRef.hide()
         // Définir à nouveau le validateur sur le contrôle 'email'
         this.formMedecin.get('email').setValidators([Validators.required, Validators.pattern(ValidationService.emailRegex)]);

         this.toastr.success(res.message, 'Ajout medecin');
      }
    }, (error) => {
              console.error('error add user agent Sante', error);
             this.loader.active = false;
             if(error.status==429){
              this.annulerFormData(this.formMedecin);
              this.resetFormValidations('formMedecin');
             }

            // this.toastr.error(error.message, '');
             this.modalRef.hide()

          }

    );
    }
  }
  editeMedecin(){

    this.submitted = true;
    console.log('this.formMedecin', this.formMedecin);
    console.log('this.formMedecin valid', this.formMedecin.valid);
    const AgentSante = Object.assign({}, this.formMedecin.value);
   // medecin.centreSante = this.idCentreSante ;
    console.log('AgentSante', AgentSante);
    if (this.formMedecin.valid) {

      this.loader.active = true;
      this.medecinSrv.editerDocteur( this.idMedecin,AgentSante).subscribe((res: any) => {

        console.log('reponse', res);
        if (res.status === 200) {
         console.log('res.status === 200');
         this.loader.active = false;
       this.medecins = res.agentSante;
         this.annulerFormData(this.formMedecin);
         this.resetFormValidations('formMedecin');
         this.modalRef.hide()
         // Définir à nouveau le validateur sur le contrôle 'email'
         this.formMedecin.get('email').setValidators([Validators.required, Validators.pattern(ValidationService.emailRegex)]);

         this.toastr.success(res.message, 'Modifier medecin');
      }
    }, (error) => {
              console.error('error add user agent Sante', error);
             this.loader.active = false;
             if(error.status==429){
              this.annulerFormData(this.formMedecin);
              this.resetFormValidations('formMedecin');
             }

            // this.toastr.error(error.message, '');
             this.modalRef.hide()

          }

    );
    }
  }
}
