import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
const API_URL = environment.backend_url;
@Component({
  selector: 'app-pop-profile-user',
  templateUrl: './pop-profile-user.component.html',
  styleUrls: ['./pop-profile-user.component.scss']
})
export class PopProfileUserComponent implements OnInit {

  @Input() userName;
  @Input() userPhone;
  @Input() userMatricule;
  @Input() userAvatar;
  @Input() beneficiaires;
  @Input() userNumeroPolice;
  apiUrl = API_URL;


 // private beneficiaires: AdherentModel[];
  constructor() { }

  
  ngOnInit(): void {
  }

}
